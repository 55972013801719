<template>
    <b-row>
        <b-col align-self="center">
            <div>
                <h1>Frequently Asked Questions</h1>
                <div>
                    <div class="faqtext" style="font-weight:bold;">
                        I don't have much time.
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        The application usually takes about 10 minutes. It's faster if you have available the value of
                        your jewelry items.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        I value my privacy.
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        We do too. We keep all personal information private and will not sell it to any other companies,
                        advertisers or third parties.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What happens when I enroll in Paperless Delivery?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        You will receive an email notification whenever a new billing or policy document is available to
                        view in “eDocuments” within your online account.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What email address will be used for Paperless Delivery?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        The email you provide with this application becomes your online account username, as well as
                        your email for Paperless Delivery. All notifications for eDocuments will be sent to this email
                        address.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        How can I view my documents online?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        You will need Adobe Reader to view the documents, which are available as PDFs. Log in to your
                        online account to view, save, or print billing and policy documents.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What if I want to cancel Paperless Delivery?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        You can cancel Paperless Delivery at any time by logging into your online account and going to
                        "Account Settings" to update your delivery preference. The transition back to mailed document
                        delivery may take up to 30 days.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What jewelry items should I include?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        Include anything you would be upset to lose. We want to help you protect every item you
                        treasure; once it's insured, you'll have peace of mind knowing we've got it covered.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What if I have loose stones?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        We cover loose stones while they are being set by your jeweler. This is important if you
                        purchase stones on the internet or elsewhere and then work with your jeweler for mounting and
                        setting of the stone. This coverage is not found in most jewelry insurance policies.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        If I lose my Tiffany® ring, will I be able to get the same name-brand replacement?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        With our repair or replacement policy, we replace with "same kind and quality," especially
                        important when you have name-brand jewelry, such as Tiffany®, Cartier®, or Harry Winston®. Our
                        goal is to make repaired or replaced jewelry virtually indistinguishable from the original, so
                        you don't have to sacrifice quality.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        Why is a recent appraisal important?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        The value of jewelry can change over time. A current appraisal helps make sure you're covered
                        for the most accurate amount - especially important in instances where your jewelry is worth
                        more at the time of a claim than it was when you last submitted an appraisal. We want to help
                        you avoid incurring additional out-of-pocket expenses to pay for the difference.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        How do I find a professional appraiser?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        We accept current appraisals or evaluations from any appraiser. You may refer to the
                        <a
                            target="_blank"
                            class="jm-NavyBlue"
                            href="http://www.americangemsociety.org/find-an-appraiser"
                        >
                            American Gem Society
                        </a>
                        or the
                        <a
                            target="_blank"
                            class="jm-NavyBlue"
                            href="http://www.najaappraisers.com/html/find_an_appraiser.php"
                        >
                            National Association of Jewelry Appraisers
                        </a>
                        for a list of appraisers by state. The appraisers for both organizations are specifically
                        trained in jewelry appraising.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        I'm giving the jewelry as a gift.
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        We always try to contact the primary applicant with any questions we have using the phone and
                        email provided, so make sure the contact information you provide is accurate. We will only
                        contact the other wearer if we cannot reach the primary applicant.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        Why does it matter who wears the jewelry?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        Identifying the actual wearer of the jewelry allows us to provide the right amount of coverage
                        based on a number of factors, such as where the wearer lives and how often the item is worn.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What is my routing number?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        The routing number is different in the U.S. and Canada. Check out the
                        <a class="jm-NavyBlue" href="/img/US_Check.jpg">
                            U.S. example
                        </a>
                        and the
                        <a class="jm-NavyBlue" href="/img/CACheck.png">
                            Canadian example
                        </a>
                        .
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        Why do you ask if I or the wearer have been convicted of a crime?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        We ask these questions to determine eligibility for insurance as part of the underwriting
                        process.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What is mysterious disappearance?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        Mysterious disappearance is when you can't pinpoint when or where the item went missing. For
                        example, you open your suitcase and your necklace is gone. It's one of the most common ways
                        jewelry goes missing, and with us, you're covered.
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        What is unscheduled jewelry coverage?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        An unscheduled jewelry option is available to anyone with at least one jewelry item scheduled on
                        their policy. It provides limited coverage for jewelry valued at $1,000 or less without an
                        appraisal. Many choose this option because it offers coverage and peace of mind for spontaneous
                        jewelry purchases. Call us for details at
                        <a style="color:#095990" href="tel:+8888842424">888-346-9464</a>
                        .
                    </div>
                    <div class="faqtext" style="font-weight:bold;">
                        Why Jewelers Mutual Group and not my homeowners or renter's policy?
                    </div>
                    <div class="faqtext-content" style="font-weight:normal;">
                        If you think your jewelry is covered by an existing policy, read it thoroughly and evaluate
                        whether or not you're comfortable with that level of coverage. Standard homeowners policies
                        often provide limited jewelry protection, frequently excluding jewelry damage and loss. Many
                        homeowners and renters policies may also force customers to use impersonal jewelry repair or
                        replacement companies, or to obtain several estimates before proceeding with a claim. We believe
                        suffering the loss or damage of a diamond ring or other piece of valuable jewelry is hard
                        enough. We do not require multiple estimates and you have the flexibility to work with your own
                        trusted jeweler for repair or replacement work.
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'Faq'
};
</script>

<style scoped lang="scss">
.faqtext-content {
    padding: 0 0 15px 25px;
}
.faqtext {
    padding: 5px 2px 2px 2px;
}
</style>
